.progress {
  background-color: #a2d7f6;
  padding: 30px 15px;
}

.progress label {
  color: #2963a2;
  font-weight: 600;
  width: 150px;
  display: inline-block;
}

.progress-item {
  display: flex;
}

.progress-bar {
  width: 100%;
  height: 20px;
  display: flex;
}

.progress-bar-inner {
  display: inline-block;
  height: 100%;
  transition: width 0.25s ease-in-out;
}

.progress-item:not(:last-child) {
  margin-bottom: 15px;
}

.progress-bar-label {
  display: inline-flex;
  height: 100%;
  padding-left: 10px;
  font-weight: 600;
}
