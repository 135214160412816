.pointLegend {
  padding: 15px;
  cursor: default;
}
.pointLegend h3 {
  color: #2963a2;
  margin: 10px 0 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e0e0e0;
}

.pointLegend-inner {
  display: flex;
  justify-content: space-between;
}

.pointLegend-item-circle {
  padding-top: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.pointLegend-item-circle-wrapper {
  max-width: 50px;
  margin: 0 auto;
}
.pointLegend-item {
  flex: 1;
  text-align: center;
  position: relative;
}

.pointLegend-item-circle label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
}

.pointLegend-item-label {
  margin-top: 15px;
}

.pointLegend-item:not(:last-child)::after {
  content: ' ';
  height: 1px;
  background-color: #e0e0e0;
  top: 37%;
  width: 100vw;
  position: absolute;
  display: none;
}
