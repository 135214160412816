.tabs {
  display: flex;
  background-color: #e0e0e0;
  overflow-y: hidden;
  position: relative;
}

.tab {
  padding: 15px;
  cursor: pointer;
  transition: all 0.125s ease-in-out;
  white-space: nowrap;
}

.tab.active {
  background-color: #136ed2;
  color: white;
}

.tab:not(.active):hover {
  background-color: #7fa4cc;
}

.tabs::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.tabs::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.tabs::-webkit-scrollbar-thumb {
  background: #666;
  border: 0px none #ffffff;
}
.tabs::-webkit-scrollbar-thumb:hover {
  background: #666;
}
.tabs::-webkit-scrollbar-thumb:active {
  background: #666;
}
.tabs::-webkit-scrollbar-track {
  background: #cccccc;
  border: 0px none #ffffff;
}
.tabs::-webkit-scrollbar-track:hover {
  background: #cccccc;
}
.tabs::-webkit-scrollbar-track:active {
  background: #cccccc;
}
.tabs::-webkit-scrollbar-corner {
  background: #cccccc;
}

.tab i {
  margin-right: 10px;
}
.tabs-wrapper {
  position: relative;
}
.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 8px;
  background: #e0e0e0;
  opacity: 0.75;
  z-index: 1;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.scroll:hover {
  opacity: 1;
  background-color: #136ed2;
  color: white;
}

.scroll.left {
  left: 0;
  display: none;
}
.scroll.right {
  right: 0;
}
