.evaluation {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  padding: 15px;
  padding-top: 0;
  border-top: 2px solid #e0e0e0;
}

.evaluationButton {
  background: #e0e0e0;
  padding: 10px 15px;
  cursor: pointer;
}

.evaluation .progress {
  background-color: transparent;
  padding: 0;
}
.evaluation .progress label {
  color: black;
}
.evaluation > div {
  background: hsl(0 0% 96% / 1);
  padding: 15px;
  margin-bottom: 15px;
}
.evaluation h3 {
  margin: 0 0 10px;
}
