.dataRating {
  margin-top: 30px;
}

.dataRatingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.25rem;
}

.dataRatingRow:nth-child(even) {
  background: #eaf0f8;
}

.dataRatingRow-points {
  white-space: nowrap;
}

.dataRatingRow-point {
  box-sizing: border-box;
  border: 2px solid #e0e0e0;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
}
.dataRatingRow-point:not(:last-child) {
  margin-right: 15px;
}

.dataRatingRow-point[data-value='0'].active,
.dataRatingRow-point[data-value='0']:hover {
  background-color: rgb(230, 122, 121);
}

.dataRatingRow-point[data-value='1'].active,
.dataRatingRow-point[data-value='1']:hover {
  background-color: rgb(251, 164, 77);
}

.dataRatingRow-point[data-value='2'].active,
.dataRatingRow-point[data-value='2']:hover {
  background-color: rgb(255, 215, 112);
}

.dataRatingRow-point[data-value='3'].active,
.dataRatingRow-point[data-value='3']:hover {
  background-color: rgb(138, 204, 202);
}
.dataRatingRow-point[data-value='4'].active,
.dataRatingRow-point[data-value='4']:hover {
  background-color: rgb(116, 171, 51);
}

.dataRating-sub {
  margin-bottom: 30px;
}
.dataRating-sub-label {
  font-size: 1.5rem;
  padding-left: 15px;
  margin-bottom: 15px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .dataRatingRow {
    flex-direction: column;
    align-items: baseline;
  }
  .dataRatingRow-label {
    margin-bottom: 15px;
  }
}
